import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MainMenuLink from "./MainMenuLink";
import { IsAdmin, IsPartnerUser } from "src/slices/authSlice";
import { useSelector } from "react-redux";

const NavLinkListStyle = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  alignItems: "flex-start",
  alignText: "center",
  justifyContent: "flex-end",
  display: "flex",
  flexGrow: 1,
}));

export default function NavList({ sx }) {
  const isAdmin = useSelector(IsAdmin);
  const isPartner = useSelector(IsPartnerUser);

  return (
    <NavLinkListStyle sx={{ ...sx }}>
      <MainMenuLink
        text="Tributes"
        childLinks={[
          {
            label: "Upload Tributes",
            asCreateButton: true,
          },
          { label: "View Tributes", route: "obituaries" },
        ]}
      />
      <MainMenuLink to="services" text="Services" />
      <MainMenuLink to="shop" text="Shop" />
      <MainMenuLink
        to={isPartner ? "partner" : "partnersignup"}
        text="Partner"
      />
      <MainMenuLink
        text="Resources"
        childLinks={[
          { label: "About", route: "about" },
          { label: "Learn", route: "learn" },
        ]}
      />
      {isAdmin && (
        <MainMenuLink
          text="Admin"
          childLinks={[
            { label: "Draft Tributes", route: "/admin/obituaries" },
            { label: "Products", route: "/admin/product" },
            {
              label: "Learn",
              childLinks: [
                { label: "Articles", route: "/admin/articles" },
                { label: "Topics", route: "/admin/topics" },
              ],
            },
            { label: "Orders", route: "/admin/orders" },
            { label: "Users", route: "/admin/users" },
            { label: "Landing Pages", route: "/admin/landing" },
          ]}
        />
      )}
    </NavLinkListStyle>
  );
}
